











import { Component, Prop, Vue } from 'vue-property-decorator'
import { Route } from 'vue-router'

@Component
export default class ImageButton extends Vue {
  @Prop()
  private readonly to!: Route
}
